import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ContactForm from "../../components/contactForm"
import Hero from "../../components/hero"
import Services from "../../components/services2"
import Content from "../../components/content"

/**
 * GraphQL Query to retrieve page data
 *
 * @return {Object}
 */
export const ServicesPageQuery = graphql`
  {
    site {
      siteMetadata {
        email
        phone
      }
    }
    hero: file(relativePath: { eq: "pages/about/header-home-erick.jpg" }) {
      sharp: childImageSharp {
        fluid(
          quality: 100
          maxWidth: 1920
          duotone: { highlight: "#15181f", shadow: "#15181f", opacity: 55 }
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

/**
 * Services Page Component
 * This component is used to generate the Services page
 *
 * @param {Object} props
 */
const InmovilizacionBienesPage = ({ data }) => {
  return (
    <Layout isSecondary={true}>
      <SEO title="Inmovilización de bienes" description="" />

      <Hero
        type="simple"
        tagline="Estos son los principales servicios jurídicos que le ofrecemos"
        title="Inmovilización de bienes"
        background={data.hero.sharp.fluid}
      />

      <Content
        tagline=""
        title="Inmovilización de bienes"
      >
        <p>
        La inmovilización de bienes registrados en el Registro General de la Propiedad de Guatemala
        constituye una medida de protección para que los bienes y derechos inscritos, no puedan
        ser enajenados o gravados sin el consentimiento del propietario.
        </p>        
        <p>
        El licenciado Erick Claveria recomienda a todo guatemalteco que reside en los Estados Unidos
        de América que realice esta medida protección de inmovilización de sus bienes inmuebles
        en Guatemala para tener una mejor tranquilidad y resguardo de sus bienes.
          </p>
          <p>
        La Ley Guatemalteca permite inmovilizar una propiedad por uno, dos o tres años. Al vencimiento
        del período que se haya escogido por parte del usuario, puede renovarse.
            </p>
       <p>
       El propietario que inmovilizó la propiedad puede dejar sin efecto dicha inmovilización
       en cualquier momento.
         </p>          
      </Content>

      <Services />

      <ContactForm
        email={data.site.siteMetadata.email}
        phone={data.site.siteMetadata.phone}
      />
    </Layout>
  )
}

export default InmovilizacionBienesPage

